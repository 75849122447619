













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ApexChart from 'vue-apexcharts';
import { chartLineValues } from '@/types/metric';
import TimestampButtons from '@/components/visualization-metric/TimestampButtons.vue';
import { ITimestamp } from '@/types/common';
import { store } from '@/store';

@Component({ name: 'LineChart', components: { TimestampButtons, ApexChart } })
export default class LineChart extends Vue {
  @Prop() private readonly metricId!: number;
  @Prop() private readonly data!: chartLineValues;
  @Prop() private readonly videoDuration!: number;

  private selectedTab = '';
  private yMax = 0;
  private heightBlock = '';

  get series() {
    return this.getSeries?.[this.selectedTab];
  }

  get chartOptions() {
    return {
      chart: {
        height: 400,
        type: 'area',
        background: 'transparent',
      },
      theme: { mode: 'dark' },
      dataLabels: { enabled: false },
      stroke: { curve: 'smooth', width: 1.5 },
      xaxis: {
        type: 'category',
        categories: this.xLinesNames,
        labels: {
          show: true,
          style: { colors: '#fff' },
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        max: this.yMax,
        tickAmount: this.yMax,
        labels: {
          show: true,
          style: { colors: '#fff' },
          formatter: (value: any) => value,
        },
        title: {
          text: this.$t.subtitle.chart.calls,
          style: {
            color: '#555555',
            fontSize: '12px',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontWeight: 400,
          },
        },
      },
      grid: {
        borderColor: 'grey',
        xaxis: { lines: { show: true } },
      },
      legend: {
        position: 'left',
        markers: {
          width: 40,
          height: 15,
        },
      },
      tooltip: {
        y: {
          formatter: (value: any) => {
            if (value !== 0) return value;
          },
        },
      },
    };
  }

  /**
   * Параметры по оси X
   */
  private xLinesStep = 600; // 10 минут
  get xLinesCount() {
    return Math.ceil(this.videoDuration / this.xLinesStep);
  }
  get xLinesNames() {
    const names: string[] = [];
    for (let i = 0; i <= this.xLinesCount; i++) {
      const newScale = this.$secondsToTime(this.xLinesStep * i);
      names.push(newScale);
    }
    return names;
  }

  /**
   * Данные для кнопок с временными метками
   */
  get timestamps(): { [tab: string]: Array<ITimestamp> } | undefined {
    if (!this.data) return;
    const timestampsByNames: { [name: string]: Array<ITimestamp> } = {};
    const timestampsAll: Array<ITimestamp> = [];

    this.data.forEach(({ value: name, time: times }) => {
      const timesByName: Array<ITimestamp> = [];
      times.forEach((time) => {
        const item: ITimestamp = {
          from: time,
          color: this.colors[name],
          label: this.$secondsToTime(time),
          tooltip: name,
        };
        timesByName.push(item);
        timestampsAll.push(item);
      });
      timestampsByNames[name] = timesByName;
    });
    return {
      Все: timestampsAll.sort((a, b) => a.from - b.from),
      ...timestampsByNames,
    };
  }

  get getSeries() {
    if (!this.data) return;
    const preparedData: any = {};
    const preparedDataAll: Array<any> = [];

    this.data.forEach(({ value: name, time }) => {
      const item = {
        name,
        color: this.colors[name],
        data: this.getCoordinates(time),
      };
      preparedData[name] = [item];
      preparedDataAll.push(item);
    });

    return {
      Все: preparedDataAll,
      ...preparedData,
    };
  }

  get labels() {
    return ['Все', ...this.data.map(({ value }) => value)];
  }

  get colors() {
    const colors: any = {};
    this.labels.forEach((el) => {
      const color: any = this.$getStudentColor(el, 'HEX');
      colors[el] = color;
    });
    return colors;
  }

  @Watch('data')
  dataUpdated() {
    [this.selectedTab] = this.labels;
  }

  @Watch('selectedTab')
  watchSelectedTab(tab: string) {
    if (this.timestamps) {
      store.player.mutations.saveIntervals({ merticId: this.metricId, intervals: this.timestamps[tab] });
      this.$emit('change-intervals');
    }
  }

  mounted() {
    [this.selectedTab] = this.labels;

    this.$nextTick(() => {
      const block = document.getElementById('line-chart');
      this.heightBlock = String(block?.getBoundingClientRect().height);
    });
  }

  getCoordinates(times: Array<number>): number[] {
    const coordinates: number[] = [];
    if (!times) return [];

    this.xLinesNames.forEach((nameLine, indxLine) => {
      let pointsOnLine = 0;
      const zoneBeforeLine = this.xLinesStep * indxLine - this.xLinesStep / 2;
      const zoneAfterLine = this.xLinesStep * indxLine + this.xLinesStep / 2;
      times.forEach((pointTime: number) => {
        if (pointTime > zoneBeforeLine && pointTime < zoneAfterLine) pointsOnLine++;
      });
      if (this.yMax < pointsOnLine) this.yMax = pointsOnLine;
      coordinates.push(pointsOnLine);
    });
    return coordinates;
  }
}
